import React, { useMemo } from 'react';
import { Navigate } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { MainRoutes, SecuredRoutes } from './types';

export const history = createBrowserHistory();

export const lazy = (fn) => function Lazy() {
  const Component = useMemo(() => React.lazy(fn), []);

  return (
    <Component />
  );
};

/*

http://localhost:4301/reinitilisation-mot-de-passe?code=d6e02ef71ce102de07d91a00057dfdce196129ba9cd95ee9892ebacfa6273d31504dcc33116793c2991e654eb0738d357209fc4b573b5e986658cd04e740442db09bb25ab7b52a0da1186b32e683e755b8195606b59d4da4021015a65e3ad3d697bacad46e38a50fc56cac5e5ad7fd9d31098f1bf4b52de7da7b217c64472dcead474acdcb78756d0550dbea6931284372e9bc1f11046dac7fc6a58dfabc2b38ba2b65272e036a4657c1fb532ba76ea70582653e1d54bc3e0edb59755bffb8bfb5c9dc955c638978451f05cc811bc2c16f8bc7acd0e3e6f590a860c2ec29f3cb8335a67488c3423e8beeee3c1ed71a05ec3a43d905736c594d5e6dbbbb25a548cd4381a7f588d38ff58fcfeb7025b050c18d4520b169af9201fa25283722e2d0e587b1492a833530549f276050b095089c173bdb9dd9ef5bd8bcdbc0c1a4b0e29b87caf24ce6855b07944d0bf69568a6923a4893515757430bb04a52ea5f5eb17b81b74269116b96a16528aba8db67485f5d1fcc658361e99fd8d7632f99b0d548ba6059abf16dda95c8812eb63baaf66291bc6cc5d219e657ff9e338da576b496fe3ebad718de11408628468dc1313c8700cda6c90d76dff61421b4ecb2bb2260b276535075612810bf8f78341908527a310ba417a4df97de1e3bed476606ccbaec6d466a4233a49f4949bf2b957216bf7d14ca

*/

const ManageRoutes = {
  path: SecuredRoutes.ROOT,
  name: 'ManageLayout',
  component: lazy(() => import('../pages/ManageLayout')),
  children: [
    {
      path: SecuredRoutes.ROOT,
      exact: true,
      name: 'Dashboard',
      component: lazy(() => import('../pages/Dashboard')),
    },
    {
      path: SecuredRoutes.BUSINESS_FIELDS,
      exact: true,
      name: 'BusinessFields',
      component: lazy(() => import('../pages/Admin/BusinessFields')),
    },
    {
      path: SecuredRoutes.ACCOUNT,
      exact: true,
      name: 'Account',
      component: lazy(() => import('../pages/User')),
    },
    {
      path: '/organisations/:id/:name/edit',
      exact: true,
      name: 'OrganisationEdit',
      component: (lazy(() => import('../pages/Organisations/OrganisationEdit'))),
    },
    {
      path: '/organisations/:id/:name/draft',
      exact: true,
      name: 'OrganisationDraft',
      component: (lazy(() => import('../pages/Admin/OrganisationDraft'))),
    },
    {
      path: SecuredRoutes.ORGANISATION_CREATE,
      exact: true,
      name: 'OrganisationCreate',
      component: (lazy(() => import('../pages/Organisations/OrganisationCreate'))),
    },
    {
      path: '*',
      name: 'NotFoundRedirect',
      component: () => <Navigate to={MainRoutes.NOT_FOUND} replace />,
    },
  ],
};

export default [
  /*
  {
    path: DirectoryRoutes.ORGANISATIONS_LIST,
    exact: true,
    name: 'OrganisationsList',
    component: (lazy(() => import('../pages/Organisations/OrganisationsList'))),
  },
  {
    path: '/:id/:name',
    exact: true,
    name: 'Organisation',
    component: (lazy(() => import('../pages/Organisations/Organisation'))),
  },
  */
  {
    path: MainRoutes.ROOT,
    exact: true,
    name: 'Directory',
    component: (lazy(() => import('../pages/MainLayout'))),
    children: [
      ManageRoutes,
    ],
  },
  {
    path: MainRoutes.NOT_FOUND,
    exact: true,
    name: 'NotFound',
    component: (lazy(() => import('../pages/errors/NotFound'))),
  },
  {
    path: MainRoutes.LOG_IN,
    exact: true,
    name: 'Login',
    component: (lazy(() => import('../pages/Login'))),
  },
  {
    path: MainRoutes.LOGGED_OUT,
    exact: true,
    name: 'LoggedOut',
    component: (lazy(() => import('../pages/LoggedOut'))),
  },
  {
    path: MainRoutes.SIGN_UP,
    exact: true,
    name: 'SignUp',
    component: (lazy(() => import('../pages/SignUp'))),
  },
  {
    path: MainRoutes.ACCOUNT_DELETED,
    exact: true,
    name: 'AccountDeleted',
    component: (lazy(() => import('../pages/User/AccountDeleted'))),
  },
  {
    path: MainRoutes.REINIT_PASSWORD,
    exact: true,
    name: 'ReinitPassword',
    component: (lazy(() => import('../pages/ReinitPassword'))),
  },
  {
    path: '*',
    name: 'NotFoundRedirect',
    component: () => <Navigate to={MainRoutes.NOT_FOUND} replace />,
  },
];
