export enum MainRoutes {
  LOG_IN = '/connexion',
  SIGN_UP = '/enregistrement',
  FORGOT_PASSWORD = '/mot-de-passe-oublie',
  NOT_FOUND = '/non-trouve',
  ACCOUNT_DELETED = '/compte-supprime',
  REINIT_PASSWORD = '/reinitilisation-mot-de-passe',
  LOGGED_OUT = '/deconnexion',
  ROOT = '/',
}

export enum SecuredRoutes {
  ROOT = '/',
  ORGANISATIONS = '/organisations',
  ACCOUNT = '/mon-compte',
  ORGANISATION_CREATE = '/organisations/create',
  BUSINESS_FIELDS = '/business-fields',
}

export enum DirectoryRoutes {
  ORGANISATIONS_LIST = '/',
}
