import { combineReducers } from '@reduxjs/toolkit';
import appSlice from 'App/slice';
import networkSlice from 'network/slice';
import adminSlice from 'pages/Admin/slice';
import authSlice from 'auth/slice';
import signUpSlice from 'pages/SignUp/slice';
import organisationsSlice from 'pages/Owner/slice';
import userSlice from 'pages/User/slice';
import organisationSlice from 'pages/Organisations/slice';
import businessFieldsSlice from 'pages/Admin/BusinessFields/slice';

const rootReducer = combineReducers({
  [authSlice.name]: authSlice.reducer,
  [signUpSlice.name]: signUpSlice.reducer,
  [appSlice.name]: appSlice.reducer,
  [networkSlice.name]: networkSlice.reducer,
  [adminSlice.name]: adminSlice.reducer,
  [organisationsSlice.name]: organisationsSlice.reducer,
  [userSlice.name]: userSlice.reducer,
  [organisationSlice.name]: organisationSlice.reducer,
  [businessFieldsSlice.name]: businessFieldsSlice.reducer,
});

export default rootReducer;
