import { createSlice } from '@reduxjs/toolkit';
import { assignPayloadToKey } from 'store/utils';
import {
  KEY, ORGANISATIONS, ORGANISATION, ORGANISATION_FILTER,
  ORGANISATION_EDIT, LAST_UPDATED_ORGANISATIONS,
} from './constants';

const initialState = {
  [ORGANISATIONS]: null,
  [ORGANISATION]: null,
  [ORGANISATION_FILTER]: '',
  [ORGANISATION_EDIT]: null,
  [LAST_UPDATED_ORGANISATIONS]: null,
};

const reducers = {
  setOrganisations: assignPayloadToKey(ORGANISATIONS),
  setOrganisation: assignPayloadToKey(ORGANISATION),
  setOrganisationFilter: assignPayloadToKey(ORGANISATION_FILTER),
  setOrganisationEdit: assignPayloadToKey(ORGANISATION_EDIT),
  setLastUpdatedOrganisations: assignPayloadToKey(LAST_UPDATED_ORGANISATIONS),
};

const slice = createSlice({
  name: KEY,
  initialState,
  reducers,
});

export default slice;
