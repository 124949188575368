export const KEY = 'organisation';

export const ORGANISATIONS = 'organisations';
export const ORGANISATIONS_STATUS = 'organisationsStatus';

export const ORGANISATION_FILTER = 'organisationFilter';

export const ORGANISATION = 'organisation';
export const ORGANISATION_STATUS = 'organisationStatus';

export const ORGANISATION_UPDATE_STATUS = 'organisationUpdateStatus';

export const ORGANISATION_EDIT = 'organisationEdit';
export const ORGANISATION_EDIT_STATUS = 'organisationEditStatus';

export const ORGANISATION_CREATE_STATUS = 'organisationCreateStatus';

export const LAST_UPDATED_ORGANISATIONS = 'lastUpdatedOrganisations';
